import { javascript } from '@api/index'
import { useHydration } from '@stores/utils'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export type UserStoreType = ReturnType<typeof useUserStore>
export const allUserStores = ref<Array<UserStoreType>>([])

type UserDataType = Backend.Models.User & {
  custom_attributes: (Backend.Models.CustomAttribute & { folder_id: number; folder_name: string })[]
  custom_operations: Backend.Models.CustomOperation[]
  is_open_ai_enabled: boolean
  is_drive_enabled: boolean
  url_whitelist: Backend.Models.IUrlWhitelistEntry[]
  urls: {
    help: string
  },
  permissions: {
    groups: number[],
    adminForGroups: number[],
    profiles: number[],
    adminForProfiles: number[],
    managerForProfiles: number[],
    organisations: number[],
    adminForOrganisations: number[],
    systemSupport: number[]
  }
}

export const createUserStore = (uniqueId: string | number) => {
  const userStore = defineStore('user-store-' + String(uniqueId), () => {
    const hydration = useHydration<UserDataType>(javascript.loggedUser)
    const hydrateById = (
      id: number,
      fields: Array<keyof UserDataType> = []
    ) => {
      return hydration.hydrate({ id }, fields)
    }
    allUserStores.value.push(userStore as unknown as UserStoreType)

    const attributeLibrary = hydration.hydratedComputed(
      'custom_attributes',
      (models) => {
        return models.map((model) => {
          const question = {
            att: model.name,
            desc: model.question_text,
            type: model.question_type,
            opts: {
              placeholder: model.question_hint,
              required: model.question_required,
              ...model.question_options
            },
            folder_id: model.folder_id,
            folder_name: model.folder_name
          } as unknown as Backend.Questionnaire.IQuestion 

          return window.AvvParser.normalize(
            question
          ) as Backend.Questionnaire.IQuestion & { att: string, folder_id: number, folder_name: string }
        })
      }
    )

    const attributeLibraryByAttribute = computed(() =>
      attributeLibrary.value.reduce<
        Record<string, Backend.Questionnaire.IQuestion>
      >((memo, attribute) => {
        memo[attribute.att] = attribute
        return memo
      }, {})
    )

    const operationLibrary = hydration.hydratedComputed(
      'custom_operations',
      (models) => {
        return models.map((model) =>
          Object.assign({}, model, {
            text: model.text ? Ast.stringify(model.text) : model.text
          }) as Backend.Models.CustomOperation & {text: string | undefined, folder_name: string}
        )
      }
    )

    return {
      ...hydration,
      hydrateById,
      id: hydration.hydratedComputed('id'),
      displayName: hydration.hydratedComputed('display_name'),
      openAiEnabled: hydration.hydratedComputed('is_open_ai_enabled'),
      driveEnabled: hydration.hydratedComputed('is_drive_enabled'),
      urlWhitelist: hydration.hydratedComputed('url_whitelist'),
      attributeLibrary,
      attributeLibraryByAttribute,
      operationLibrary,
      permissions: hydration.hydratedComputed('permissions'),
      urls: hydration.hydratedComputed('urls')
    }
  })
  return userStore
}

export const useUserStore = createUserStore('current')
